import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  MapContainer,
  TileLayer,
  Circle,
  Tooltip,
  useMapEvents,
  GeoJSON,
  useMap,
  Polygon,
} from "react-leaflet";
import {
  Page,
  TopNav,
  Paragraph,
  Button,
  ErrorSummary,
  ErrorText,
  Link,
  Footer,
  H3,
  ListItem,
  UnorderedList,
} from "govuk-react";
import styled from "styled-components";
import "leaflet/dist/leaflet.css";
import { Loader } from "@googlemaps/js-api-loader";
import * as turf from "@turf/turf";

import EastSussexLogo from "./components/logo";
import boundaryData from "./data/drt_zone.json";

const StyledTopNav = styled(TopNav)`
  background-color: #003c69;
`;

const StyledPage = styled(Page)`
  .govuk-footer {
    padding: 0;
    background-color: #003c69;
  }
`;

const StyledInput = styled.input`
  width: calc(100% - 24px);
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #0b0c0c;
  border-radius: 0;
  font-size: 16px;
`;

const StyledButton = styled(Button)`
  background-color: #003c69;
  color: #fff;
`;

const FlexiBusMap = () => {
  const [startLocation, setStartLocation] = useState(null);
  const [isValidLocation, setIsValidLocation] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [clippedCircle, setClippedCircle] = useState(null);
  const autocompleteInputRef = useRef(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyBrkcOTsv0PtKqhWIEuyU3a1wvVbjHr8kc",
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      // Define the bounds for East Sussex
      const eastSussexBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(50.73, -0.25), // SW corner
        new window.google.maps.LatLng(51.22, 0.83) // NE corner
      );
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteInputRef.current,
        {
          componentRestrictions: { country: "gb" },
          fields: ["geometry", "name"],
          bounds: eastSussexBounds, // Bias results to East Sussex
          strictBounds: true, // Restrict results to within these bounds
        }
      );

      // Set the bounds every time the autocomplete instance is created
      autocomplete.setBounds(eastSussexBounds);

      // Add a listener to reapply the bounds after each search
      autocomplete.addListener("place_changed", () => {
        autocomplete.setBounds(eastSussexBounds);
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          handleLocationChange({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
        }
      });
    });

    // Parse URL parameters
    const params = new URLSearchParams(window.location.search);
    const lat = params.get("lat");
    const lng = params.get("lng");
    if (lat && lng) {
      handleLocationChange({ lat: parseFloat(lat), lng: parseFloat(lng) });
    }
  }, []);

  const handleLocationChange = (location) => {
    const point = turf.point([location.lng, location.lat]);
    const isWithinBoundary = turf.booleanPointInPolygon(point, boundaryData);

    if (isWithinBoundary) {
      setStartLocation(location);
      setIsValidLocation(true);
      setShowTooltip(false);

      // Create a 14-mile radius circle
      const circle = turf.circle(point, 14, { units: "miles" });

      // Clip the circle with the boundary
      const clipped = turf.intersect({
        type: "FeatureCollection",
        features: [circle, boundaryData],
      });
      setClippedCircle(clipped);
    } else {
      setStartLocation(location);
      setIsValidLocation(false);
      setShowTooltip(false);
      setClippedCircle(null);
    }

    // Update URL parameters
    const params = new URLSearchParams(window.location.search);
    params.set("lat", location.lat.toFixed(6));
    params.set("lng", location.lng.toFixed(6));
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };

  const handleMapClick = (e) => {
    handleLocationChange(e.latlng);
  };

  const clearLocation = () => {
    setStartLocation(null);
    setIsValidLocation(true);
    setShowTooltip(true);
    setClippedCircle(null);
    if (autocompleteInputRef.current) {
      autocompleteInputRef.current.value = "";
    }
    // Clear URL parameters
    window.history.replaceState({}, "", window.location.pathname);
  };

  const MapEvents = () => {
    const map = useMap();

    const updateMapView = useCallback(() => {
      if (startLocation) {
        map.setView([startLocation.lat, startLocation.lng], map.getZoom());
      }
    }, [map]);

    useEffect(() => {
      updateMapView();
    }, [updateMapView]);

    useMapEvents({
      click: handleMapClick,
    });

    return null;
  };

  return (
    <StyledPage
      header={
        <StyledTopNav
          serviceTitle="Flexibus – Your travel area"
          company={
            <StyledTopNav.Anchor
              href="https://www.eastsussex.gov.uk/"
              target="new"
            >
              <StyledTopNav.IconTitle
                icon={<EastSussexLogo width="250" height="55" />}
              ></StyledTopNav.IconTitle>
            </StyledTopNav.Anchor>
          }
        />
      }
      footer={
        <Footer
          licence={""}
          meta={
            <meta-custom__MetaCustom>
              Built by <Link href="https://podaris.com">Podaris</Link> on behalf
              of East Sussex County Council. Contains OS data © Crown copyright
              and database right 2024 ©.
            </meta-custom__MetaCustom>
          }
        />
      }
    >
      <div>
        <Paragraph>
          Flexibus is a flexible, on-demand ride-share service for areas
          in East Sussex with limited public transport. Flexibus can 
pick you up and take you to destinations within a 14 mile 
          radius, as long as the journey cannot be done by a 
          timetabled bus route or train.
        </Paragraph>
        <Paragraph>
          Book via the [Ride Pingo app](https://ridepingo.com/) or call 01273 078203 (9am to 5pm, Monday to Friday).
        </Paragraph>
        <Paragraph>
          Find out more about Flexibus on the [East Sussex County Council
          website](https://www.eastsussex.gov.uk/roads-transport/public/flexibus?utm_source=travelcheckermap&utm_medium=web&utm_campaign=flexibus).
        </Paragraph>
        <Paragraph>
          Click on your pick up point on the map below to see where you can travel.
        </Paragraph>
        
        <StyledInput
          ref={autocompleteInputRef}
          type="text"
          placeholder="Search for pick-up point or click on the map"
        />
        {!isValidLocation && (
          <ErrorSummary
            heading="Invalid Location"
            description="Please select a location within the Flexibus travel zone."
          >
            <ErrorText>Location outside service area</ErrorText>
          </ErrorSummary>
        )}
        <div style={{ height: "60vh", marginTop: "20px" }}>
          <MapContainer
            center={[50.939976, 0.267792]}
            zoom={10}
            minZoom={8}
            style={{ height: "100%", width: "100%" }}
            attributionControl={false}
          >
            <TileLayer
              crossOrigin="anonymous"
              url="https://api.mapbox.com/styles/v1/podarismaps/ckbt8v5rq0nkj1io1yz3g9sqk/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoicG9kYXJpc21hcHMiLCJhIjoiY2prdGxwZjN1MDZqNTNwcXFnOXkwN25sMiJ9.YlmyWPbcwpezqi2G7bBgHA"
              attribution='Contains OS data © Crown copyright and database right 2024 © <a href="https://www.mapbox.com/map-feedback/">Mapbox</a>'
            />
            <MapEvents />
            <GeoJSON
              data={boundaryData}
              style={{ color: "#003c69", weight: 2, fillOpacity: 0.1 }}
            />
            {clippedCircle && (
              <Polygon
                positions={(clippedCircle.geometry.type === "MultiPolygon"
                  ? clippedCircle.geometry.coordinates
                  : [clippedCircle.geometry.coordinates]
                ).map((r) => r.map((c) => c.map((p) => [p[1], p[0]])))}
                pathOptions={{
                  color: "#ff0000",
                  fillColor: "#bed600",
                  weight: 4,
                  fillOpacity: 0.4,
                  dashArray: "5, 10",
                  opacity: 1,
                }}
              ></Polygon>
            )}

            {isValidLocation && startLocation && (
              <Circle
                center={startLocation}
                radius={8}
                pathOptions={{ color: "#003c69", fillColor: "#003c69" }}
              >
                <Tooltip permanent>
                  You can use Flexibus in this area
                </Tooltip>
              </Circle>
            )}

            {!isValidLocation && startLocation && (
              <Circle
                center={startLocation}
                radius={100}
                pathOptions={{ color: "red", fillColor: "red" }}
              >
                <Tooltip permanent>
                  This location is outside the Flexibus service area.
                </Tooltip>
              </Circle>
            )}

            {showTooltip && (
              <Tooltip permanent position={[50.9097, 0.2481]}>
                Click on the map to set your starting location
              </Tooltip>
            )}
          </MapContainer>
        </div>
        {startLocation && (
          <div style={{ marginTop: "20px" }}>
            <StyledButton onClick={clearLocation}>Clear Location</StyledButton>
          </div>
        )}
      </div>
    </StyledPage>
  );
};

export default FlexiBusMap;
